:root {
  /**
 * This file contains only Custom Sets of Properties (@apply-rule).
 * This was originally part of cssnext package and was an interesting
 * concept, because property sets would not suffer from specificity
 * problems that arise from creating a single CSS file on build step.
 *
 * However, W3C has abandoned this concept before it became an
 * official standard. It would be a good idea to reduce the usage.
 * http://tabatkins.github.io/specs/css-apply-rule/
 *
 * Property sets defined in this file are imported and injected to
 * correct places during PostCSS build step.
 *
 * Note: if you add plain CSS styles to this file,
 * you'll end up seeing it repeated in development mode on every
 * component that @imports this file.
 */

  /* ================ CSS Custom Property Sets ================ */

  /* Full screen Background image located in root-folder/src/assets */
  --backgroundImage: {
    /* Gradient direction and overlaying the black color on top of the image for better readability */
    background-image: var(--marketplaceColorGradient), url('../../assets/crops_pattern.png');

    /* Add loading color for the div */
    background-color: var(--successColor);

    /* Cover the whole screen with the background image */
    background-size: cover, 120px;

    background-repeat: no-repeat, repeat;

    /* Align the image within the container */
    background-position: top left;

    background-attachment: fixed;
  }

  /**
   * Usage example: .root { @apply --marketplaceDefaultFontStyles; }
   */
  --marketplaceDefaultFontStyles: {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
    }
  }

  --marketplaceSmallFontStyles: {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
  }

  /* ================ Body font ================ */

  --marketplaceBodyFontStyles: {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  /* ================ Hero title ================ */

  --marketplaceHeroTitleFontStyles: {
    font-weight: var(--fontWeightSemiBold);
    font-size: 36px;
    line-height: 36px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 25px;
    margin-bottom: 14px;

    @media (--viewportMedium) {
      font-size: 64px;
      line-height: 64px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 25px;
      margin-bottom: 23px;
    }
  }

  /* ================ Topbar ================ */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 24px 0;
    text-decoration: inherit;

    @media (--viewportMedium) {
      margin: 24px 0;
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {
      border-bottom: 4px solid var(--marketplaceColor);
      border-radius: 0;
      text-decoration: none;
    }
  }

  /* ================ Modal title ================ */

  --marketplaceModalTitleStyles {
    font-weight: var(--fontWeightSemiBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightSemiBold);
      line-height: 40px;
      margin: 0;
    }
  }

  /* ================ H1, H2, H3, H4, H5 & H6 ================ */

  --marketplaceH1FontStyles: {
    font-weight: var(--fontWeightSemiBold);
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.5px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 18px;
    margin-bottom: 18px;

    @media (--viewportMedium) {
      font-size: 40px;
      font-weight: var(--fontWeightSemiBold);
      line-height: 48px;
      letter-spacing: -1px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  --marketplaceH2FontStyles: {
    font-weight: var(--fontWeightSemiBold);
    font-size: 23px;
    letter-spacing: normal;
    line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 21px;
    margin-bottom: 17px;

    @media (--viewportMedium) {
      line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 21px;
      margin-bottom: 19px;
    }
  }

  --marketplaceH3FontStyles: {
    font-weight: var(--fontWeightSemiBold);
    font-size: 18px;
    line-height: 28px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 16px;
    margin-bottom: 14px;

    @media (--viewportMedium) {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  --marketplaceH4FontStyles: {
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 17px;
      margin-bottom: 15px;
    }
  }

  --marketplaceH5FontStyles: {
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 14px;
    }
  }

  --marketplaceH6FontStyles: {
    font-weight: var(--fontWeightBold);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 6px;
    }
  }

  /* ================ Other fonts ================ */

  --marketplaceTinyFontStyles: {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;
    -webkit-font-smoothing: subpixel-antialiased;

    @media (--viewportMedium) {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
  }

  --marketplaceMessageFontStyles {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;
  }

  --marketplaceMessageDateFontStyles {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 12px;
    line-height: 18px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      font-size: 14px;
      line-height: 24px;
    }
  }

  --marketplaceTxTransitionFontStyles {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 18px;

    @media (--viewportLarge) {
      font-size: 20px;
      line-height: 32px;
    }
  }

  --marketplaceSearchFilterLabelFontStyles {
    background-image: none;
    background-color: #f6f8f6;
    border: 0;
    border-radius: 12px;
    color: var(--marketplaceColorGray);
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightSemiBold);
    font-size: 13px;
    line-height: 18px;

    &:hover,
    &:focus {
      background-image: none;
      background-color: var(--marketplaceColorPale);

      & svg {
        fill: var(--matterColorLight);
      }
    }

    @media (--viewportMedium) {
      font-size: 13px;
      line-height: 20px;
    }

    & svg {
      margin-right: 10px;
    }
  }

  --marketplaceSearchFilterSublabelFontStyles {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    line-height: 18px;
  }

  --marketplaceListingAttributeFontStyles {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
  }

  /* ================ Tabbed navigation font styles ================ */

  --marketplaceTabNavFontStyles {
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;

    @media (--viewportLarge) {
      font-weight: var(--fontWeightSemiBold);
      font-size: 20px;
      line-height: 24px;
    }
  }

  --marketplaceTabNavHorizontalFontStyles {
    font-weight: var(--fontWeightSemiBold);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
  }

  /* ================ Clearfix solution ================ */

  --clearfix {
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }

  /* ================ Links ================ */

  --marketplaceLinkStyles: {
    /* Position and dimensions */
    display: inline;
    margin: 0;
    padding: 0;

    /* Borders */
    border: none;

    /* Colors */
    color: var(--marketplaceColor);

    /* Text size should be inherited */
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  /* ================ Buttons ================ */

  --marketplaceButtonFontStyles: {
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    font-weight: var(--fontWeightSemiBold);
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;

    @media (--viewportMedium) {
      /* TODO: Make sure button text aligns with the baseline */
    }
  }
  --marketplaceButtonStyles {
    @apply --marketplaceButtonFontStyles;

    /* Dimensions */
    display: block;
    width: 100%;
    min-height: 59px;
    margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
    padding: 17px 0 17px 0;

    @media (--viewportMedium) {
      padding: 20px 0 20px 0;
    }

    /* Borders */
    border: none;
    border-radius: 60px;

    /* Colors */
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);

    /* Text */
    text-align: center;
    text-decoration: none;

    /* Effects */
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 65px;
    }
  }
  --marketplaceButtonStylesChevronHoverEffect {
    transition: var(--transitionStyleButton);
    transform-origin: left;

    &:hover {
      transform: scaleX(1.03);
    }
  }
  --marketplaceButtonStylesLetterHoverEffect {
    transition: var(--transitionStyleButton);
    transform-origin: left;

    &:hover {
      transform: scaleX(1.03);
    }
  }
  --marketplaceButtonStylesChevronWhite {
    @apply --marketplaceButtonStylesChevronHoverEffect;
    background-image: url("data:image/svg+xml,%3Csvg width='15px' height='25px' viewBox='0 0 15 25' xmlns='http://www.w3.org/2000/svg' %3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-326.000000, -20.000000)' fill='%23FEFEFE' fill-rule='nonzero'%3E%3Cg transform='translate(326.000000, 20.000000)'%3E%3Cpolygon points='2.15 24.1 0 21.95 9.9 12.05 0 2.15 2.15 0 14.2 12.05'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position-y: center;
    background-position-x: calc(100% - 20px);
    background-repeat: no-repeat;
  }
  --marketplaceButtonStylesChevronPurple {
    @apply --marketplaceButtonStylesChevronHoverEffect;
    background-image: url("data:image/svg+xml,%3Csvg width='15px' height='25px' viewBox='0 0 15 25' xmlns='http://www.w3.org/2000/svg' %3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-326.000000, -20.000000)' fill='%23c53ac7' fill-rule='nonzero'%3E%3Cg transform='translate(326.000000, 20.000000)'%3E%3Cpolygon points='2.15 24.1 0 21.95 9.9 12.05 0 2.15 2.15 0 14.2 12.05'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position-y: center;
    background-position-x: calc(100% - 20px);
    background-repeat: no-repeat;

    &:hover,
    &:focus {
      background-image: url("data:image/svg+xml,%3Csvg width='15px' height='25px' viewBox='0 0 15 25' xmlns='http://www.w3.org/2000/svg' %3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-326.000000, -20.000000)' fill='%23fefefe' fill-rule='nonzero'%3E%3Cg transform='translate(326.000000, 20.000000)'%3E%3Cpolygon points='2.15 24.1 0 21.95 9.9 12.05 0 2.15 2.15 0 14.2 12.05'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
    &:disabled {
      background: none;
    }
  }
  --marketplaceButtonStylesChevronGreen {
    @apply --marketplaceButtonStylesChevronHoverEffect;
    background-image: url("data:image/svg+xml,%3Csvg width='15px' height='25px' viewBox='0 0 15 25' xmlns='http://www.w3.org/2000/svg' %3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-326.000000, -20.000000)' fill='%232ad397' fill-rule='nonzero'%3E%3Cg transform='translate(326.000000, 20.000000)'%3E%3Cpolygon points='2.15 24.1 0 21.95 9.9 12.05 0 2.15 2.15 0 14.2 12.05'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position-y: center;
    background-position-x: calc(100% - 20px);
    background-repeat: no-repeat;

    &:hover,
    &:focus {
      background-image: url("data:image/svg+xml,%3Csvg width='15px' height='25px' viewBox='0 0 15 25' xmlns='http://www.w3.org/2000/svg' %3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-326.000000, -20.000000)' fill='%23fefefe' fill-rule='nonzero'%3E%3Cg transform='translate(326.000000, 20.000000)'%3E%3Cpolygon points='2.15 24.1 0 21.95 9.9 12.05 0 2.15 2.15 0 14.2 12.05'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
    &:disabled {
      background: none;
    }
  }
  --marketplaceButtonStylesLetterWhite {
    @apply --marketplaceButtonStylesLetterHoverEffect;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.38607 17.3182C1.91363 17.3182 1.51145 17.1523 1.17952 16.8204C0.847599 16.4884 0.681641 16.0862 0.681641 15.6138V2.3862C0.681641 1.91377 0.847599 1.51159 1.17952 1.17965C1.51145 0.847736 1.91414 0.681778 2.38759 0.681778H8.55464V2.08078H2.38834C2.31142 2.08078 2.24091 2.11283 2.17679 2.17693C2.11269 2.24104 2.08064 2.31156 2.08064 2.38848V15.6115C2.08064 15.6884 2.11269 15.759 2.17679 15.8231C2.24091 15.8872 2.31142 15.9192 2.38834 15.9192H15.6114C15.6883 15.9192 15.7588 15.8872 15.8229 15.8231C15.887 15.759 15.9191 15.6884 15.9191 15.6115V9.44523H17.3181V15.6123C17.3181 16.0857 17.1521 16.4884 16.8202 16.8204C16.4883 17.1523 16.0861 17.3182 15.6137 17.3182H2.38607ZM6.75179 12.2336L5.76624 11.2481L14.9383 2.08078H10.8383V0.681778H17.3181V7.16153H15.9191V3.06153L6.75179 12.2336Z' fill='white'/%3E%3C/svg%3E");
    background-position-y: center;
    background-position-x: calc(100% - 20px);
    background-repeat: no-repeat;
  }
  --marketplaceButtonStylesPrimary {
    @apply --marketplaceButtonStyles;

    background-color: var(--marketplaceColorCTA);
    color: var(--matterColorLight);

    &:hover,
    &:focus {
      background-color: var(--marketplaceColorLight);
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
    }
  }
  --marketplaceButtonStylesSecondary {
    @apply --marketplaceButtonStyles;

    background-color: transparent;
    color: var(--marketplaceColorCTA);

    /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
    padding: 18px 0 0 0;

    border-width: 2px;
    border-style: solid;
    border-color: var(--marketplaceColorCTA);

    &:hover,
    &:focus {
      background-color: var(--marketplaceColorCTA);
      border-color: var(--marketplaceColorCTA);
      color: var(--matterColorLight);
    }
    &:disabled {
      color: var(--matterColorLight);
      border-color: var(--matterColorNegative);
    }
  }
  --marketplaceButtonStylesSecondary {
    @apply --marketplaceButtonStyles;

    background-color: transparent;
    color: var(--marketplaceColorCTA);

    /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
    padding: 18px 0 0 0;

    border-width: 2px;
    border-style: solid;
    border-color: var(--marketplaceColorCTA);

    &:hover,
    &:focus {
      background-color: var(--marketplaceColorCTA);
      border-color: var(--marketplaceColorCTA);
      color: var(--matterColorLight);
    }
    &:disabled {
      color: var(--matterColorLight);
      border-color: var(--matterColorNegative);
    }
  }
  --marketplaceButtonStylesTertiary: {
    @apply --marketplaceButtonStylesSecondary;

    border-color: var(--marketplaceColor);
    color: var(--marketplaceColor);

    &:hover,
    &:focus {
      background-color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
    }
  }
  --marketplaceButtonStylesSecondaryWhite: {
    @apply --marketplaceButtonStylesSecondary;

    border-color: var(--matterColorLight);
    color: var(--matterColorLight);

    &:hover,
    &:focus {
      background-color: var(--matterColorLight);
      border-color: var(--matterColorLight);
    }
  }
  --marketplaceButtonStylesSecondaryGreen: {
    @apply --marketplaceButtonStylesSecondary;

    border-color: var(--marketplaceColorLight);
    color: var(--marketplaceColorLight);

    &:hover,
    &:focus {
      background-color: var(--marketplaceColorLight);
      border-color: var(--marketplaceColorLight);
    }
  }

  /* ================ Inputs ================ */

  --marketplaceInputStyles: {
    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 10px;

    background-color: var(--matterColorLight);

    /* Borders */
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--marketplaceColor);
    border-radius: 0;

    &::placeholder {
      color: #93a097;
      opacity: 1;
    }

    /* Effects */

    transition: border-bottom-color var(--transitionStyle);

    &:hover,
    &:focus {
      border-bottom-color: var(--matterColor);
      outline: none;
    }

    &:disabled {
      color: var(--matterColorAnti);
      border-bottom-color: var(--matterColorAnti);
    }
  }

  --marketplaceInputFontStyles: {
    font-family: var(--fontFamily);
    font-feature-settings: 'ss02';
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 17px;
      line-height: 32px;
    }
  }

  --marketplaceSelectStyles: {
    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 8px;

    /* Unset user agent styles */
    -webkit-appearance: none;
    appearance: none;

    /* Borders */
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--marketplaceColor);
    border-radius: 0;

    /* Background */
    background-color: var(--matterColorLight);
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%232ad397" fill="%232ad397" fill-rule="evenodd"/></svg>');
    background-size: 16px 16px;
    background-position: calc(100% - 10px) center;

    /* Effects */

    cursor: pointer;
    transition: border-bottom-color var(--transitionStyle);

    &:hover,
    &:focus {
      border-bottom-color: var(--matterColor);
      outline: none;
    }

    @media (--viewportMedium) {
      padding: 4px 8px 2px 8px;
    }
  }

  /* ================ Modals ================ */

  --marketplaceModalRootStyles {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
    flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
    display: flex;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
    }
  }

  --marketplaceModalFormRootStyles {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    height: 100%;
  }

  --marketplaceModalBaseStyles {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: 22px;
    border-bottom: none;

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      /* min-height: 568px; */
      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
    }
  }

  --marketplaceModalInMobileBaseStyles {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0;

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);

    @media (--viewportMedium) {
      flex-basis: 480px;
      flex-grow: 0;
      height: 100%;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }
  }

  /* Modal title */

  --marketplaceModalTitleStyles {
    font-weight: var(--fontWeightSemiBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightSemiBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
  }

  /* Modal message */

  --marketplaceModalParagraphStyles {
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;
    margin: 18px 0 0 0;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightRegular);
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.11px;
      margin: 24px 0 0 0;
    }
  }

  /* Bottom wrapper for the modal */

  --marketplaceModalBottomWrapper {
    text-align: center;
    margin-top: 60px;
    align-self: stretch;

    @media (--viewportMedium) {
      margin-top: 96px;
    }
  }

  /* Adjust the bottomWrapper's margin to match modals without the helper text */
  --marketplaceModalBottomWrapperText {
    margin-top: -24px;
    padding-bottom: 8px;

    @media (--viewportMedium) {
      padding-bottom: 0;
    }
  }

  --marketplaceModalHelperText {
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColorAnti);
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
  }

  --marketplaceModalHelperLink {
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColor);
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
  }

  /* Modal Close icon */

  --marketplaceModalCloseStyles {
    /* Position inside modal */
    position: fixed;
    top: 0;
    right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
    z-index: calc(var(--zIndexModal) + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
    display: flex;
    align-items: flex-start;
    width: auto;

    /* Sizing (touch area) */
    padding: 24px;
    margin: 0;
    border: 0;

    @media (--viewportMedium) {
      padding: 27px 30px;
      position: absolute;
    }

    /* Colors */
    background-color: transparent;
    color: var(--matterColor);

    cursor: pointer;

    &:enabled:hover {
      background-color: transparent;
      color: var(--matterColorDark);
      box-shadow: none;
      text-decoration: none;
    }
    &:enabled:active {
      background-color: transparent;
      color: var(--matterColorDark);
    }
    &:disabled {
      background-color: transparent;
    }
  }

  --marketplaceModalIconStyles {
    height: 48px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      height: 64px;
      margin-bottom: 23px;
    }
  }

  --marketplaceModalCloseText {
    /* Font */
    @apply --marketplaceH6FontStyles;
    margin: 0;

    @media (--viewportMedium) {
      margin: 0;
    }
  }

  --marketplaceModalCloseIcon {
    display: inline-block;
    margin-left: 8px;

    /* Move X icon vertically to align it with the close text. */
    margin-top: 3px;

    box-sizing: content-box;

    @media (--viewportMedium) {
      margin-top: 2px;
    }
  }

  --marketplaceModalErrorStyles {
    @apply --marketplaceH4FontStyles;
    margin: 24px;
    color: var(--failColor);
  }

  --marketplaceModalPasswordMargins {
    /* Leave space between the input and the button below when the
    viewport height is small */
    margin-top: 24px;

    @media (--viewportMedium) {
      margin-top: 32px;
    }
  }

  --marketplaceModalGreen: {
    background-color: var(--marketplaceColorLightest);
    border-bottom: 30px solid var(--matterColorLight);
    padding: 30px;
    padding: 30px 0;
    text-align: center;
  }

  --marketplaceModalGreenTitle: {
    font-size: 24px;
    letter-spacing: 0.02em;
    max-width: 360px;
    margin: 0 auto;
  }

  --marketplaceModalGreenMessage: {
    font-size: 16px;
    max-width: 360px;
    margin: 24px auto;
  }

  --marketplaceModalGreenEmail: {
    background: var(--matterColorLight);
    border-radius: 16px;
    color: var(--marketplaceColor);
    display: block;
    font-weight: var(--fontWeightSemiBold);
    margin: 0 auto;
    max-width: 90%;
    padding: 0 20px;
  }

  --listingWizardHeader: {
    background-color: var(--matterColorLight);
    color: var(--marketplaceColor);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 72px;
    padding: 10px 50px 6px 40px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    font-weight: var(--fontWeightSemiBold);

    @media (--viewportLarge) {
      font-size: 18px;
    }
  }

  --cropsieSettingsTitle: {
    margin-top: 8px;
    margin-bottom: 19px;
    font-weight: 600;
    font-size: 23px;
    line-height: 120%;

    letter-spacing: -0.02em;
    color: var(--marketplaceColor);
    padding-top: 55px;
    @media (--viewportMedium) {
      padding-top: 25px;
      margin-bottom: 54px;
    }
    @media (--viewportLarge) {
      padding-top: 0;
    }
  }

  --cropsieSettingsPageContent: {
    padding: 25px;
    @media (--viewportMedium) {
      padding: 0;
      padding-left: 90px;
    }
  }

  --cropsieSettingsPageForm: {
    max-width: 564px;
  }

  --cropsieSettingsPageFormSubmitButton {
    width: 285px;
    max-height: 65px;
  }

  --cropsieSettingsPageFormInput {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    border-bottom-color: var(--marketplaceColorLight);
  }

  --cropsieSettingsPageFormBottomWrapper: {
    margin-top: 46px;
    height: 140px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 45px;
    background-color: var(--matterColorLight);

    @media (--viewportLarge) {
      margin-top: 96px;
    }
  }

  --listingCartWrapperContent: {
    @media (--viewportLarge) {
      max-width: 1128px;
    }

    @media (--viewportXLarge) {
      max-width: 1056px;
    }
  }
}
